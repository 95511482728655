<template>
	<div>
		<div class="modal fade" :id="(!this.isEmpty(this.modal_id)) ? 'newTaskModal_' + this.modal_id: 'newTaskModal'" ref="newTaskModal" tabindex="-1" aria-labelledby="Создание/редактирование задачи" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex mb-20 border-0 p-0">
						<span v-if="this.event_type == 'call'"
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Звонок' : 'Новый звонок'" />
							  <span v-else-if="this.event_type == 'call_owner' && this.is_check_owner"
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Результат звонка собственнику' : 'Результат звонка собственнику'" />	  
						<span v-else-if="this.event_type == 'call_owner'"
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Звонок собственнику' : 'Звонок собственнику'" />	  

						<span v-else-if="this.event_type == 'meet'"
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Встреча' : 'Новая встреча'" />

						<span v-else-if="this.event_type == 'show'"
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Показ' : 'Новый показ'" />

						<span v-else-if="this.event_type == 'deal'"
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Сделка' : 'Новая сделка'" />

						<span v-else-if="this.event_type == 'even'"
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Задача' : 'Новая задача'" />

						<span v-else-if="this.event_type == 'file'"
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Файлы' : 'Добавить файлы'" />

						<span v-else-if="this.event_type == 'partner_id'"
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Партнёр' : 'Новый партнёр'" />

						<span v-else
							  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
							  v-text="(this.event_id) ? 'Задача' : 'Новая задача'" />

						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body d-flex flex-column p-0">
						
						<form class="form row-fluid row-cols-1 needs-validation" novalidate>
							<div v-if="this.inArray(this.event_type, ['call', 'meet','show','deal','even', 'call_owner'])" class="col">
								<div v-if="(this.item.is_tracking && !this.isEmpty(this.item.is_tracking)) ? Boolean(this.item.is_tracking) : false">
									<div class="row-fluid row-cols-auto align-items-center flex-row mb-3">
										<DateTimePicker inputId="schedule_date"
														inputName="schedule_date"
														ref="schedule_date"
														inputLabel="Выберите дату и время с:"
														:pickTime="true"
														:hasInputElement="false"
														:use12HourClock="false"
														inputRequired="true"
														:inputValue="(!this.isEmpty(this.item.schedule_date)) ? this.item.schedule_date : new Date().toDateString()"
														@setValue="(value) => this.setAndValidate('schedule_date', value)" />
									</div>
									<div class="row-fluid row-cols-auto align-items-center flex-row mb-3">
										<DateTimePicker inputId="schedule_date_to"
														inputName="schedule_date_to"
														inputLabel="Выберите дату и время по:"
														ref="schedule_date_to"
														:pickTime="true"
														:hasInputElement="false"
														:use12HourClock="false"
														inputRequired="true"
														:inputValue="(!this.isEmpty(this.item.schedule_date)) ? this.item.schedule_date_to : new Date().toDateString()"
														@setValue="(value) => this.setAndValidate('schedule_date_to', value)" />
									</div>
								</div>
								<div v-else class="row-fluid row-cols-auto align-items-center flex-row mb-3">
									<DateTimePicker inputId="schedule_date"
													inputName="schedule_date"
													ref="schedule_date"
													:inputLabel="(this.is_check_owner) ? 'Выберите дату и время закрытия' : 'Выберите дату и время:'"
													:pickTime="true"
													:hasInputElement="false"
													:use12HourClock="false"
													inputRequired="true"
													:inputValue="(!this.isEmpty(this.item.schedule_date)) ? this.item.schedule_date : null"
													@setValue="(value) => this.setAndValidate('schedule_date', value)" />
								</div>
							</div>
							<div v-if="this.inArray(this.event_type, ['call'])" class="col">
								<Switcher inputId="is_tracking"
										  inputName="is_tracking"
										  ref="is_tracking"
										  inputLabel="Связь с телефонией"
										  :inputValue="!this.isEmpty(this.item.is_tracking) ? this.item.is_tracking : null"
										  inputClass="px-0 mb-3"
										  inputWrapClass="flex-column mb-3"
										  @setValue="(value) => this.setAndValidate('is_tracking', !Boolean(this.item.is_tracking))" />
							</div>
							<div v-if="this.inArray(this.event_type, ['deal'])" class="col">
								<InputText inputId="summ"
										   inputLabel="Сумма:"
										   inputName="summ"
										   ref="summ"
										   :inputValue="(!this.isEmpty(this.item.summ)) ? this.item.summ : null"
										   inputClass="rounded-1 p-2"
										   inputRequired="true"
										   inputWrapClass="flex-column mb-2"
										   @setValue="(value) => this.setAndValidate('summ', value)"/>
							</div>
							<div v-if="this.inArray(this.event_type, ['meet', 'show'])" class="col">
								<InputText inputId="address"
										   inputLabel="Адрес:"
										   inputName="address"
										   ref="address"
										   :inputValue="(!this.isEmpty(this.item.address)) ? this.item.address : null"
										   inputClass="rounded-1 p-2"
										   inputRequired="true"
										   inputWrapClass="flex-column mb-2"
										   @setValue="(value) => this.setAndValidate('address', value)"/>
							</div>
							<div v-if="this.inArray(this.event_type, ['deal'])" class="col">
								
								<SelectBox v-if="this.inArray(this.section, ['clients'])" inputId="object_id"
										   inputName="object_id"
										   ref="object_id"
										   inputLabel="Объект:"
										   :inputOptions="this.objects_list"
										   :inputValue="(!this.isEmpty(this.item.object_id)) ? this.item.object_id : null"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputSearch="true"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setAndValidate('object_id', value)" />
								<SelectBox v-else-if="this.inArray(this.section, ['objects', 'requisitions'])" inputId="client_id"
										   inputName="client_id"
										   ref="client_id"
										   inputLabel="Клиент:"
										   :inputOptions="this.clients_list"
										   :inputValue="(!this.isEmpty(this.item.client_id)) ? this.item.client_id : null"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputSearch="true"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setAndValidate('client_id', value)" />
								
								<SelectBox inputId="document_id"
										   inputName="document_id"
										   ref="document_id"
										   inputLabel="Бланк документа:"
										   :inputOptions="this.documents_list"
										   :inputValue="(!this.isEmpty(this.item.document_id)) ? this.item.document_id : null"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputSearch="true"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setAndValidate('document_id', value)" />
							</div>
							<div v-if="this.inArray(this.event_type, ['even', 'file'])" class="col">
								<InputText inputId="title"
										   inputLabel="Название:"
										   inputName="title"
										   ref="title"
										   :inputValue="(!this.isEmpty(this.item.title)) ? this.item.title : null"
										   inputClass="rounded-1 p-2"
										   inputRequired="true"
										   inputWrapClass="flex-column mb-2"
										   @setValue="(value) => this.setAndValidate('title', value)"/>
							</div>
							<div v-if="this.inArray(this.event_type, ['file'])" class="col">
								<InputFile inputId="files"
										   inputLabel="Файлы:"
										   inputName="files"
										   ref="files"
										   :inputValue="(!this.isEmpty(this.item.files)) ? this.item.files : null"
										   inputClass="rounded-1 p-2"
										   inputWrapClass="flex-column mb-2"
										   inputRemove="true"
										   inputMultiple="true"
										   inputRequired="true"
										   @removeValue="(value) => this.removeFile('files', value)"
										   @setValue="(value) => this.uploadFile('files', value)" />

							</div>
							<div v-if="this.inArray(this.event_type, ['call', 'meet', 'show', 'deal', 'even', 'call_owner']) && this.isEmpty(this.eventId)" class="col">
								<SelectBox inputId="responsible_id"
										   inputName="responsible_id"
										   ref="responsible_id"
										   inputLabel="Ответственный:"
										   :inputOptions="this.responsibles_list"
										   :inputValue="(!this.isEmpty(this.who_work_responsibles)) ? this.who_work_responsibles : null"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputSearch="true"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setAndValidate('responsible_id', value)" />
							</div>
							<div v-if="this.inArray(this.event_type, ['call', 'meet', 'show', 'deal', 'even', 'file', 'call_owner'])" class="col">
								<TextArea inputId="comment"
										  inputName="comment"
										  ref="comment"
										  inputLabel="Комментарий:"
										  :inputValue="(!this.isEmpty(this.item.comment)) ? this.item.comment : null"
										  inputLabelClass="text-font-secondary mb-2"
										  inputClass="rounded-3 p-2"
										  inputWrapClass="mb-20"
										  @setValue="(value) => this.setAndValidate('comment', value)" />
							</div>
							<div v-if="this.inArray(this.event_type, ['partner_id'])" class="col">
								<SelectBox inputId="partner_id"
										   inputName="partner_id"
										   ref="partner_id"
										   inputLabel="Партнёр(ы):"
										   :inputOptions="this.employees_list"
										   :inputValue="(!this.isEmpty(this.item.partner_id)) ? this.item.partner_id : null"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputSearch="true"
										   inputRequired="true"
										   inputMultiple="true"
										   inputWrapClass="mb-20"
										   @setValue="(value) => this.setAndValidate('partner_id', value)" />
							</div>
							
							<div v-if="this.inArray(this.event_type, ['call_owner']) && !this.isEmpty(this.status_list)" class="col">
								<SelectBox inputId="status_id"
										   inputName="status_id"
										   ref="status_id"
										   inputLabel="Статус объекта:"
										   :inputOptions="this.status_list"
										   :inputValue="(!this.isEmpty(this.status_id)) ? this.status_id : null"
										   inputLabelClass="text-font-secondary mb-3"
										   inputClass="rounded-3 p-2"
										   inputSearch="true"
										   inputWrapClass="mb-20"
										   inputRequired="true"
										   inputMultiple="false"
										   @setValue="(value) => this.setAndValidate('status_id', value)" />
							</div>	
							<div v-if="this.inArray(this.event_type, ['call_owner']) && this.is_check_owner" class="col modal-footer">
								<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Дата следующего звонка собственнику</span>
								<div class="row-fluid row-cols-auto align-items-center flex-row mb-3">
									<DateTimePicker inputId="new_owner_date"
													inputName="new_owner_date"
													ref="new_owner_date"
													inputLabel="Выберите дату и время:"
													:pickTime="true"
													:hasInputElement="false"
													:use12HourClock="false"
													inputRequired="true"
													:inputValue="(!this.isEmpty(this.item.new_owner_date)) ? this.item.new_owner_date : null"
													@setValue="(value) => this.setAndValidate('new_owner_date', value)" />
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer p-0 pt-3">
						<button type="button"
								class="btn btn-success col-12 rounded-3 py-3 text-white fs-3"
								:disabled="this.isEmpty(this.event_type)"
								@click="this.addEditEvent()">
							Добавить
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from "@/api";
import CommonService from "@/services/CommonService";
import InputText from "@/components/inputs/InputText";
import TextArea from "@/components/inputs/TextArea";
import SelectBox from "@/components/inputs/SelectBox";
import Switcher from "@/components/inputs/Switcher";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import InputFile from "@/components/inputs/InputFile";
import InputSelect from "@/components/inputs/InputSelect";

export default {
	name: 'NewTaskModal',
	components: {InputFile, DateTimePicker, Switcher, SelectBox, TextArea, InputText},
	props: {
		modalId: { type: String },
		objectId: { type: Number },
		clientId: { type: Number },
		requisitionId: { type: Number },
		eventType: { type: String },
		eventId: { type: Number },
		eventSection: { type: String },
		stageId: { type: Number },
		fieldName: { type: String },
		statusId: { type: String },
		isCheckOwner: {type: Boolean},
		podSection: {type: Number, default: 0}
	},
	data() {
		return {
			modal_id: (typeof this.modalId !== "undefined") ? this.modalId : '',
			pod_section: (typeof this.podSection !== "undefined") ? this.podSection : 0,
			item: {},
			errors: [],
			objects_list: [],
			clients_list: [],
			documents_list: [],
			responsibles_list: [],
			employes_list: [],
			status_list: [],
			uploadReadyState: true,
			uploadProgress: 0,
			who_work_responsibles: null,
			//event_id: null,
		}
	},

	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		getObjects() {
			return api.get('/objects/list', {
				params: {
					objects: this.item.objects
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getObjects()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					let groups = (response.data.groups) ? response.data.groups : [];

					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, object]) => {

							if (object.group_id) {

								let group_id = object.group_id;
								if (groups[group_id]) {

									list.push({
										value: false,
										name: '<b>' + groups[group_id] + '</b>'
									});

									delete groups[group_id];
								}
							}

							list.push({
								value: object.id,
								name: object.name
							});
						});
					}

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getObjects()::axios', error);

			});
		},
		getClients() {
			return api.get('/common/clients', {
				params: {
					objects: this.item.objects
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getObjects()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, client]) => {

							list.push({
								value: client.id,
								name: client.name
							});
						});
					}

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getObjects()::axios', error);

			});
		},
		getDocuments() {
			return api.get('/docs/list', {
				params: {}
			})
			.then((response) => {

				CommonService.log('debug', 'getDocuments()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, document]) => {
							list.push({
								value: document.id,
								name: document.name
							});
						});
					}

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getDocuments()::axios', error);

			});
		},
		getResponsibles(){
			
			var id = 0;
			
			switch(this.eventSection){
				case 'requisitions': {
					id = this.requisitionId;
					break;
				}
				case 'clients': {
					id = this.clientId;
					break;
				}
				case 'objects': {
					id = this.objectId;
					break;
				}
			}


			let _this = this;
			
			return api.get('/common/responsibles', {	
				params: {
						id: id,
						section: this.eventSection,
					}
				}
			)
			.then((response) => {


				CommonService.log('debug', 'getResponsibles()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					
					

					if (response.data.responsibles) {
						Object.entries(response.data.responsibles).forEach(([key, responsible]) => {
							list.push({
								value: responsible.id,
								name: responsible.name
							});
						});
					}

					if(response.data.who_work){
						_this.who_work_responsibles = response.data.who_work;
					}

					if (typeof _this.$store !== "undefined")
						_this.$store.commit('setEmployeesListExcluded', list);

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getResponsibles()::axios', error);

			});
			//return {};
		},

		
		getEmployees() {

			let _this = this;
			return api.get('/common/employees', {
				params: {
					self_exclude: true,
					add_groups: false
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getEmployees()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					let groups = (response.data.groups) ? response.data.groups : [];
					//console.log(groups);

					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, employee]) => {

							if (employee.group_id) {

								let group_id = employee.group_id;
								if (groups[group_id]) {

									list.push({
										value: 'all_' + group_id,
										name: '<b>' + groups[group_id] + '</b>'
									});

									delete groups[group_id];
								}
							}

							let department_name = (employee.department)  ? ' (' + employee.department + ')' : '';
							list.push({
								value: employee.id,
								name: (employee.group_id) ? '&nbsp;&nbsp;-&nbsp;' + employee.name : employee.name + department_name
							});
						});
					}

					if (typeof _this.$store !== "undefined")
						_this.$store.commit('setEmployeesListExcluded', list);

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getEmployees()::axios', error);

			});
		},
		getListStatus: function(){
				return api.get('/object/statusLists',{
					params: {
						object_id: this.object_id,
						section: 'task'
					}
					})
					.then((response) => {
						
						CommonService.log('debug', 'getListStatus()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {

							let listStatus = [];
							if (response.data.status_lists) {
								Object.entries(response.data.status_lists).forEach(([key, status]) => {
									listStatus.push({
										value: status.id.toString(),
										name: status.name
									});
								});
							}
							
							return listStatus;
						} else {
							return {};
						}

						
					}).catch(function (error) {

						CommonService.log('error', 'getListStatus()::axios', error);

					});
			},
		hideModal() {
			let modalEl = this.$refs.newTaskModal;
			let modal = new bootstrap.Modal(modalEl);
			modal.hide();
		},
		showModal() {
			
			let modalEl = this.$refs.newTaskModal;
			let modal = new bootstrap.Modal(modalEl);
			this.$nextTick().then(() => {
				this.getObjects().then((data) => {
					this.objects_list = data
				});

				this.getClients().then((data) => {
					this.clients_list = data
				});

				this.getDocuments().then((data) => {
					this.documents_list = data
				});
				
				this.getResponsibles().then((data) => {
					this.responsibles_list = data
				});


				if (!this.isEmpty(this.$store.getters.employeesListExcluded))
					this.employees_list = this.$store.getters.employeesListExcluded;
				else
					this.getEmployees().then(data => { this.employees_list = data });
			})
			modal.show();
		},
		monthDiff(d1, d2) {
			var months;
			months = (d2.getFullYear() - d1.getFullYear()) * 12;   //calculates months between two years
			months -= d1.getMonth() + 1; 
			months += d2.getMonth();  //calculates number of complete months between two months
			let day1 = 30-d1.getDate();  
			let day2 = day1 + d2.getDate();
			months += parseInt(day2/30);  //calculates no of complete months lie between two dates
			return months <= 0 ? 0 : months;
		},
		setAndValidate(name, value) {

			if(name == 'responsible_id'){
				this.who_work_responsibles = value;
			} else {

			if (typeof name !== 'string')
				return;

			if (value && typeof value == 'object') {
				if (typeof value.target !== 'undefined')
					return;
			}

			if (!this.inArray(typeof (value), ['object', 'number', 'string', 'boolean']))
				return;

			let errors = [];
			let parent = null;
			let child = null;
			let parts = name.split('.', 2);
			if (parts.length == 2) {
				parent = parts[0];
				child = parts[1];
			}

			// Валидация значения
			if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {

				/*if ('invalid' in this.$refs[name])
					this.$refs[name].invalid = false;*/

				if (this.$refs[name].inputLabel) {
					let label = this.$refs[name].inputLabel.replace(/:+$/, '');

					let is_skip = false;
					if (name == 'funnel_id' && value == 0)
						is_skip = true;

					if (this.$refs[name].required && !is_skip) {
						if (value) {
							if (value.toString() == '0' || value.toString() == '-1')
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							else if (this.isEmpty(value))
								errors.push('Поле `' + label + '` обязательно к заполнению!');
						} else {
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						}
					}

					if(name == 'new_owner_date'){
						
						var delimiter = '-';
						var input = value;
						if(input == '' || this.isEmpty(input)){
							input = "1970-01-01 00:00:00";
							
						}
						var parts_o = input.trim().split(' ');
						
						var date_o = parts_o[0].split(delimiter);
						if(this.monthDiff(new Date(), new Date(date_o[0],parseInt(date_o[1])-1,date_o[2])) > 6){
							errors.push('Дата следующего звонка не может превышать 6-ти месяцев');
						}
					}

					if (!this.isEmpty(errors)) {

						this.errors[name] = {
							name: name,
							label: label,
							errors: errors
						};

						this.$refs[name].valid = false;
						this.$refs[name].invalid = errors.join('<br/>');
					} else {
						this.$refs[name].valid = true;
						this.$refs[name].invalid = false;
					}

				}
			}

			// Установка значения
			if (parent && child) {
				if (!this.isEmpty(this.item[parent])) {
					if (typeof (this.item[parent][child]) !== "undefined") {
						this.item[parent][child] = value;
						
					} else {
						this.item[parent] = {
							...{
								[child]: value
							},
							...this.item[parent]
						};
					}
				} else {
					this.item[parent] = {
						[child]: value
					};
				}
			} else if (parent == null && name) {
				this.item[name] = value;
			} else {
				if (parent && child)
					this.item[parent][child] = null;
				else
					this.item[name] = null;
			}

			this.is_form_changed = true;

			CommonService.log('debug', 'setAndValidate()', { name: name, value: value, errors: errors });
			}

		},
		removeFile(prop_name, file) {

			let section = 'tasks';
			let params = {};

			if (typeof section !== "undefined") {

				params.section = section;

				if (this.client_id)
					params.client_id = this.client_id;

				if (this.requisition_id)
					params.requisition_id = this.requisition_id;

				if (this.object_id)
					params.object_id = this.object_id;

				if (this.event_id)
					params.event_id = this.event_id;

			}

			if (typeof file !== "undefined")
				params.filename = file.name;

			console.debug('removeFile()::axios', {
				params: params
			});

			let _this = this;
			api.delete('/common/file', {
				params: params
			}).then((response) => {

				if (process.env.NODE_ENV == "development")
					console.debug('removeFile()::axios', {
						response: response.data
					});

				if (response.status == 200 && response.data) {
					if (!_this.isEmpty(this.item[prop_name])) {
						this.item[prop_name].forEach((file, index) => {
							if (file.name == response.data.get.filename) {
								this.item[prop_name].splice(index, 1);
							}
						});
					}
				}
			}).catch(function (error, response) {

				if (!_this.isEmpty(this.item[prop_name])) {
					this.item[prop_name].forEach((file, index) => {
						if (file.name == response.data.get.filename) {
							this.item[prop_name].splice(index, 1);
						}
					});
				}

				CommonService.log('error', 'removeFile()::axios', error);

			});
		},
		uploadFile(prop_name, value) {

			let uploadProgress = 0;
			let uploadReadyState = false;

			let section = 'tasks';
			let data = new FormData();

			if (value.files.length) {
				for (let file of value.files) {
					data.append(section + '[]', file);
				}

				data.append('section', section);

				if (this.client_id)
					data.append('client_id', this.client_id);

				if (this.requisition_id)
					data.append('requisition_id', this.requisition_id);

				if (this.object_id)
					data.append('object_id', this.object_id);

				if (this.event_id)
					data.append('event_id', this.event_id);
			}

			api.post('/common/upload', data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				timeout: 180000,
				onUploadProgress: progressEvent => {

					uploadProgress = Math.round((progressEvent.loaded/progressEvent.total)*100);

					if (process.env.NODE_ENV == "development")
						console.debug('uploadFile()::onUploadProgress', {
							progress: uploadProgress
						});

					if (progressEvent.loaded === progressEvent.total) {

						uploadReadyState = true;
						uploadProgress = 0;

						if (process.env.NODE_ENV == "development")
							console.debug('uploadFile()::onUploadProgress', {
								progress: 'COMPLETED!'
							});
					}
				}
			}).then((response) => {

				if (process.env.NODE_ENV == "development")
					console.debug('uploadFile()::axios', {
						response: response.data
					});

				if (response.status == 200 && response.data.success) {

					uploadReadyState = true;
					uploadProgress = 0;
					if (response.data.files.length) {

						let files = response.data.files;
						if (!this.isEmpty(files)) {
							files.forEach((file, index) => {
								let values = this.item[prop_name] ?? [];
								this.setAndValidate(prop_name, [...values, {
									"guid": file.guid,
									"name": file.filename,
									"type": file.extension,
									"is_upload": true,
									"path": file.path
								}]);
							});
						}

						this.$emit('onFileUploaded', files, response.data.section);
					}
				}

			}).catch(function (error) {

				uploadReadyState = true;
				uploadProgress = 0;

				CommonService.log('error', 'uploadFile()::axios', error);

			});

			CommonService.log('debug', 'uploadFile()', {variable: prop_name, filename: value.name, files: value.files});

		},
		validateAll(fields) {

			this.errors = [];
			let is_valid = false;
			let invalid_input = null;
			fields.forEach(name => {
				let value = (typeof (this.item[name]) !== "undefined") ? this.item[name] : null;
				this.setAndValidate(name, value);
			});

			if (typeof Object.values(this.errors) == "object") {
				this.errors = CommonService.removeEmpty(Object.values(this.errors));
				//this.errors = CommonService.resetArray(Object.values(this.errors));
				this.errors = Object.values(this.errors);
				if (typeof (this.errors[0]) !== "undefined") {
					if (!this.isEmpty(Object.values(this.errors)[0]['name'])) {
						let invalid_input = Object.values(this.errors)[0]['name'];
						if (invalid_input && typeof invalid_input !== "undefined") {
							let input = this.$refs[invalid_input].$el;
							if (input) {
								this.$nextTick(() => CommonService.scrollIntoView(input, 60));
							}
						}
					}
				}
			}

			is_valid = this.isEmpty(this.errors);

			CommonService.log('debug', 'validateAll()', {fields: fields, is_valid: is_valid, errors: this.errors});

			return is_valid;
		},
		getTask(event_id, event_type, section) {
			
			return api.get('/common/task', {
				params: {
					event_id: event_id ?? this.event_id,
					event_type: event_type ?? this.event_type,
					section: section ?? this.section,
					is_check_owner: this.is_check_owner,
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getTask()::axios', {response: response.data});
				
				let event = [];
				if (response.status == 200 && response.data.success)
					event = response.data.task.event;

				this.item = event;
				if(!this.isEmpty(this.item.comment)){
					this.item.comment = this.item.comment.replace(/<br \/>/g, '\n');
					event.comment = event.comment.replace(/<br \/>/g, '\n');
				}
				if(event.status_id) this.status_id = event.status_id;
				return event;
			}).catch(function (error) {

				CommonService.log('error', 'getTask()::axios', error);

				return null;
			});
		},
		addEditEvent() {

			let fields = [];

			if (this.section == "objects") {
				if (this.event_type == 'call') {
					fields.push('schedule_date', 'schedule_date_to', 'comment');
				} else if (this.event_type == 'meet') {
					fields.push('schedule_date', 'address', 'comment');
				} else if (this.event_type == 'show') {
					fields.push('schedule_date', 'address', 'comment');
				} else if (this.event_type == 'deal') {
					fields.push('schedule_date', 'summ', 'object_id', 'document_id', 'comment');
				} else if (this.event_type == 'call_owner' && this.is_check_owner) {
					fields.push('schedule_date', 'status_id', 'comment', 'new_owner_date');
				} else if (this.event_type == 'call_owner') {
					fields.push('schedule_date', 'status_id', 'comment');
				} else {
					fields.push('comment');
				}
			} else if (this.section == "clients") {
				if (this.event_type == 'call') {
					fields.push('schedule_date', 'schedule_date_to', 'comment');
				} else if (this.event_type == 'meet') {
					fields.push('schedule_date', 'address', 'comment');
				} else if (this.event_type == 'show') {
					fields.push('schedule_date', 'address', 'comment');
				} else if (this.event_type == 'deal') {
					fields.push('schedule_date', 'summ', 'object_id', 'document_id', 'comment');
				} else if (this.event_type == 'even') {
					fields.push('schedule_date', 'title', 'comment');
				} else if (this.event_type == 'file') {
					fields.push('files', 'comment');
				} else if (this.event_type == 'partner_id') {
					fields.push('partner_id');
				} else {
					fields.push('comment');
				}
			}/* else if (this.section == "requisitions") {

			}*/

			if (this.validateAll(fields)) {

				let data = {
					event_id: this.event_id,
					event_type: this.event_type,
					section: this.section,
					who_work: this.who_work_responsibles,
				};
				

				if (this.section == "objects"){
					data.object_id = this.object_id;
					data.is_check_owner = this.isCheckOwner;
					data.pod_section = this.pod_section;
				}
				else if (this.section == "clients")
					data.client_id = this.client_id;
				else if (this.section == "requisitions")
					data.requisition_id = this.requisition_id;
				
				let task = this.item;
				if(!this.isEmpty(task.comment)){
					task.comment = task.comment.replace(/\n/g, '<br />');
				}
				
				api.post('/common/task', {
					task,
					...data
				}).then((response) => {

					//console.log(response.data);
					/*if (process.env.NODE_ENV == "development")
						console.debug('addEditEvent()::axios', {
							response: response.data
						});*/

					if (response.status == 200 && response.data.success) {

						let tasks = response.data.tasks;
						if (tasks) {
							if (tasks.length) {

								if (!this.isEmpty(this.stage_id))
									tasks.stage_id = this.stage_id;

								if (!this.isEmpty(this.field_name))
									tasks.field_name = this.field_name;

								this.$emit('updateTasksList', tasks);
							}
						}

						let history = response.data.history;
						if (history) {
							if (history.length) {
								this.$emit('updateHistoryList', history);
							}
						}

						let date_call = response.data.date_call;

						
						let modal_id = (!this.isEmpty(this.modal_id)) ? 'newTaskModal_' + this.modal_id: 'newTaskModal';
						
						if(modal_id == 'newTaskModal_taskModalObjectCard'){
							this.$emit('updateObject', this.object_id);
						}

						if(modal_id == 'newTaskModal_taskModalAddObject'){
							this.$emit('updateDateCall', date_call);
						}
						
						let modal = CommonService.getModal(modal_id);
						if (modal) {
							modal.hide();
						}
					}
				}).catch(function (error) {

					CommonService.log('error', 'addEditEvent()::axios', error);

				});
			}
		},
	},
	mounted() {
		var _this = this;
		var modal = this.$refs.newTaskModal;
		if (modal && typeof modal !== "undefined") {
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.item = {};
				_this.errors = [];
				_this.$props.objectId = null;
				_this.$props.clientId = null;
				_this.$props.requisitionId = null;
				_this.$props.eventId = null;
				_this.$props.eventType = null;
				_this.$props.eventSection = null;
				_this.$props.statusId = null;
				_this.$props.isCheckOwner = false; 
				_this.status_list = [];
				_this.$emit('newTasksClosed');
				_this.emitter.emit("global.modalClose", modal);
			});
			modal.addEventListener('show.bs.modal', function (event) {
				
					_this.$nextTick(() => {
						
						if(_this.event_type == 'call_owner'){
							_this.getListStatus().then((data) => {
								_this.status_list = data
							})
							if(!_this.isEmpty(_this.status_id))
								_this.item.status_id = _this.status_id;
						}
						if (_this.event_id && _this.event_type) {
							_this.getTask(_this.event_id, _this.event_type).then((data) => {
								_this.item = data;
								if (_this.event_type == 'file') {
									_this.item.file_list.forEach((file, index) => {
										file['name'] = file['filename'];
										delete file['filename'];
									});

									_this.item.files = _this.item.file_list;
									//console.log(_this.item.files);
								}
							});
						}
						
					});
				
			
				if (process.env.NODE_ENV == "development") {
					console.debug('newTaskModal::show.bs.modal', {
						event_id: _this.event_id,
						event_type: _this.event_type,
						objectId: _this.$props.objectId,
						clientId: _this.$props.clientId,
						requisitionId: _this.$props.requisitionId,
						statusId: _this.$props.statusId
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		object_id() {
			return (typeof this.$props.objectId !== "undefined") ? this.$props.objectId : null;
		},
		client_id() {
			return (typeof this.$props.clientId !== "undefined") ? this.$props.clientId : null;
		},
		requisition_id() {
			return (typeof this.$props.requisitionId !== "undefined") ? this.$props.requisitionId : null;
		},
		event_type() {
			return (typeof this.$props.eventType !== "undefined") ? this.$props.eventType : null;
		},
		event_id() {
			return (typeof this.$props.eventId !== "undefined") ? this.$props.eventId : null;
		},
		section() {
			return (typeof this.$props.eventSection !== "undefined") ? this.$props.eventSection : null;
		},
		stage_id() {
			return (typeof this.$props.stageId !== "undefined") ? this.$props.stageId : null;
		},
		field_name() {
			return (typeof this.$props.fieldName !== "undefined") ? this.$props.fieldName : null;
		},
		status_id() {
			return (typeof this.$props.statusId !== "undefined") ? this.$props.statusId : null;
		},
		is_check_owner(){
			return (typeof this.$props.isCheckOwner !== "undefined") ? this.$props.isCheckOwner : false;
		},
		
	},
}
</script>